import * as React from 'react'

import { useBloomreachPageRoutes } from '@grandvisionhq/bloomreach'
import { NextPage, withErrorPage } from '@grandvisionhq/next'
import { useIntl } from '@grandvisionhq/state'
import { compose } from '@grandvisionhq/utils'
import { InitialProps, Page, Props } from '@grandvisionhq/www-next/pages/store-detail'

import { withCms, withLayout } from '../../cms'
import { defaultErrorProps } from '../../components/error-props'
import { withServerStoreDataContext } from '../../components/hocs/with-store-code-context'

const StoreDetailsPage: NextPage<Props, InitialProps> = ({ storeData, ...props }) => {
  const { getFormattedLabel } = useIntl()
  const { routes } = useBloomreachPageRoutes()

  const pageTitle = getFormattedLabel('stores.detail.page.title', {
    labelData: { town: storeData?.town ?? '', street: storeData?.streetName ?? '' },
  })

  return (
    <Page
      {...props}
      storeData={storeData}
      pageTitle={pageTitle}
      urls={{ bookAppointment: routes['book-appointment-form'] }}
    />
  )
}

StoreDetailsPage.getInitialProps = Page.getInitialProps

export default compose<Props, Props>(
  withServerStoreDataContext(),
  withCms(),
  withErrorPage(defaultErrorProps),
  withLayout()
)(StoreDetailsPage)
